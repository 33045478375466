










import Vue from 'vue'
import Component from 'vue-class-component'
import CakeCardImageSlider from '@/components/cakes/card/ImageSlider'
import CakeCardInfo from '@/components/cakes/card/Info'
import TopSaleIcon from '@/components/icons/TopSaleIcon'

@Component({
  components: {
    CakeCardImageSlider,
    CakeCardInfo,
    TopSaleIcon
  }
})
export default class CakeCardSkeleton extends Vue {
  get cakeInfo(): Record<string, number | string> {
    return {
      name: '',
      id: 0,
      price: 0,
      minWeight: 0,
      maxWeight: 0
    }
  }
}
