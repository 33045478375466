<template>
  <svg width="21" height="16" viewBox="0 0 21 16" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2702 15.4399H3.16705C2.82392 15.4399 2.53142 15.1924 2.47377 14.8549L0.884703 5.47945C0.841109 5.21789 0.946578 4.95492 1.15752 4.79742C1.36845 4.63992 1.6497 4.6118 1.88877 4.73133L6.45205 6.98133L10.1027 0.335391C10.3488 -0.111797 11.0885 -0.111797 11.3345 0.335391L14.9852 6.98133L19.5485 4.73133C19.7861 4.6118 20.0688 4.63992 20.2797 4.79742C20.4906 4.95492 20.5975 5.2193 20.5525 5.47945L18.9635 14.8549C18.9058 15.1924 18.6119 15.4399 18.2702 15.4399ZM3.76189 14.0337H17.6767L18.9367 6.60023L15.0049 8.53945C14.666 8.70821 14.2581 8.57742 14.0781 8.24836L10.7186 2.13399L7.36049 8.24836C7.18048 8.57742 6.77408 8.70821 6.43377 8.53945L2.50189 6.60023L3.76189 14.0337Z"/>
  </svg>
</template>

<script>
export default {
  name: 'TopSaleIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
