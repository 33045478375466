


























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ProductTerm } from '@/shared/models/cakes'

@Component
export default class CakeCardInfo extends Vue {
  @Prop() price!: string
  @Prop({ default: '' }) name!: string
  @Prop({ default: () => [] }) categories!: Array<ProductTerm>
  @Prop({ default: false }) isNumberCake!: boolean
  @Prop() id!: number
  @Prop() minWeight!: number
  @Prop() maxWeight!: number
  @Prop({ default: false }) isSkeleton!: boolean
  @Prop() singleWeight!: number
  @Prop() type!: string
  @Prop({ default: () => [] }) attributes!: Array<{
    id: number;
    name: string;
    option: string;
    options: Array<string>;
    variation: boolean;
    visible: boolean;
  }>;

  get category(): string {
    return this.$route.params.slug || this.categories[0]?.slug
  }
}
