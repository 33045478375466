









import Vue from 'vue'
import Component from 'vue-class-component'
import BezeButton from '@/components/common/BezeButton.vue'
import { Prop } from 'vue-property-decorator'
import { ProductListItem } from '@/shared/models/cakes'

@Component({
  components: {
    BezeButton
  }
})
export default class CakeCardActions extends Vue {
  @Prop({ required: true }) cake!: ProductListItem

  get isCakeInCart(): boolean {
    return this.$store.getters.cart.find((cake: ProductListItem) => cake.id === this.cake?.id)
  }

  addCakeToCart(): void {
    if (!this.cake) return

    const cake = {
      id: this.cake.id,
      name: this.cake.name,
      price: this.cake?.price,
      weight: this.cake.acf.cake_min_weight,
      layers: this.cake.acf.cake_min_layers || 0,
      images: [...this.cake.images],
      categories: [...this.cake.categories],
      acf: { ...this.cake.acf },
      filling: {},
      type: this.cake.type,
      shipping_class: this.cake.shipping_class
    }
    this.$store.dispatch('addToCart', cake)
  }
}
