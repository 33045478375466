


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ProductImage } from '@/shared/models/cakes'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

@Component({
  components: {
    VueSlickCarousel
  }
})
export default class CakeCardImageSlider extends Vue {
  sliderConfig = {
    lazyLoad: 'progressive',
    infinite: false,
    speed: 300,
    arrows: false,
    draggable: false,
    dots: true,
    dotsClass: 'image-slider-dots'
  }

  @Prop({
    required: true,
    default: () => []
  }) images!: Array<ProductImage>
}
