





















import Vue from 'vue'
import Component from 'vue-class-component'
import CakeCardImageSlider from '@/components/cakes/card/ImageSlider'
import CakeCardInfo from '@/components/cakes/card/Info'
import CakeCardActions from '@/components/cakes/card/Actions'
import { Prop } from 'vue-property-decorator'
import { ProductListItem } from '@/shared/models/cakes'
import TopSaleIcon from '@/components/icons/TopSaleIcon'
import RequestOrderModalButton from '@/components/common/RequestOrderModalButton'

@Component({
  components: {
    RequestOrderModalButton,
    CakeCardImageSlider,
    CakeCardInfo,
    CakeCardActions,
    TopSaleIcon
  }
})
export default class CakeCard extends Vue {
  @Prop({ required: true }) cake!: ProductListItem

  get isCakeTopSale(): boolean {
    return this.cake.tags?.findIndex(tag => tag.slug === 'top-sale') !== -1
  }

  get category(): string {
    return this.$route.params.slug || this.cake.categories[0]?.slug
  }

  get isCakeNew(): boolean {
    // 3 day difference between created day and today
    return Date.now() - new Date(this.cake.dateCreated).getTime() < (1000 * 60 * 60 * 24 * 3)
  }

  // can we order this product or make a request only
  get isOnRequestOnly(): boolean {
    return !!this.cake.virtual
  }

  get cakeInfo(): Record<string, unknown> {
    return {
      isNumberCake: this.cake.acf?.isNumberCake === 'yes',
      name: this.cake.name,
      id: this.cake.id,
      price: this.cake.price,
      minWeight: this.cake.acf.cake_min_weight,
      maxWeight: this.cake.acf.cake_max_weight,
      categories: this.cake.categories,
      singleWeight: this.cake.acf.single_weight,
      type: this.cake.type,
      attributes: this.cake.attributes
    }
  }
}
